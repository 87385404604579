import httpClient  from '.';
const API_CONTROLLER = 'report-handle/';

export default {
    getReportHandleForPaging(search) {
        let url = API_CONTROLLER + 'get-report-handle-for-paging';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                customerId: search.customerId,
                orderId: search.orderId,
                orderName: search.orderName,
                noBooking: search.noBooking,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    getReportHandle2ForPaging(search) {
        let url = API_CONTROLLER + 'get-report-handle-2-for-paging';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                customerId: search.customerId,
                orderId: search.orderId,
                orderName: search.orderName,
                noBooking: search.noBooking,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },
}